import React from "react";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";

import resume from "../assets/CV/Ivo-Ignatov-Resume.pdf";

import { AiOutlineSolution } from "react-icons/ai";

import { Link } from "react-scroll"
import { IoIosArrowDropup } from "react-icons/io";


function Footer() {
  return (
    <div className="relative lg:hidden w-full h-12 flex items-center justify-center bg-[#5a1c5f]">
      <ul className="flex">
        <li className="text-[#DF500C]">
          <a className="" href="https://www.linkedin.com/in/ivo-ignatov-5188b3133/" target="_blank"> 
            <FaLinkedin size={30} />
          </a>
        </li>
        <li className="text-[#DF500C]">
          <a className="" href="https://github.com/ivo959595" target="_blank">
            <FaGithub size={30} />
          </a>
        </li>
        <li className="text-[#DF500C]"> 
          <a className="" href={resume} download="Ivo-Ignatov-Resume" title="Download Resume"> 
             <AiOutlineSolution size={30} />
          </a>
        </li>
      </ul>

     <div className="absolute text-[#DF500C] right-2 cursor-pointer">

      <Link to="home" smooth={true} duration={500}>
            <IoIosArrowDropup className="motion-safe:animate-bounce" size={40}/>
      </Link>
      </div>
    </div>
  );
}

export default Footer;
