import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { RiDownloadFill } from "react-icons/ri";
import { AiOutlineSolution } from "react-icons/ai";
import Logo from "../assets/logo2.png";
import { Link } from "react-scroll";

import resume from "../assets/CV/Ivo-Ignatov-Resume.pdf"


function Navbar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="z-50 fixed w-full h-20 flex justify-between items-center px-2 bg-[#5a1c5f]
     text-gray-300 shadow-lg shadow-bottom-lg nav-div">
      <div>
        <Link
          className="hover:cursor-pointer"
          to="home"
          smooth={true}
          duration={500}>
          <img src={Logo} alt="logo" style={{ width: "90px" }} className="wobble-div" /> 
        </Link>
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li className="transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 duration-300">
          <Link
            className="border-b-4 border-transparent hover:border-[#DF500C] "
            to="home"
            smooth={true}
            duration={500}
          >
            Home
          </Link>
        </li>
        <li className="transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 duration-300">
          <Link
            className="border-b-4 border-transparent hover:border-[#DF500C]"
            to="about"
            smooth={true}
            duration={500}
          >
            About
          </Link>
        </li>
        <li className="transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 duration-300">
          <Link
            className="border-b-4 border-transparent hover:border-[#DF500C] "
            to="skills"
            smooth={true}
            duration={500}
          >
            Skills
          </Link>
        </li>
        <li className="transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 duration-300">
          <Link
            className="border-b-4 border-transparent hover:border-[#DF500C] "
            to="work"
            smooth={true}
            duration={500}
          >
            Work
          </Link>
        </li>
        <li className="transition ease-in-out delay-15 hover:-translate-y-1 hover:scale-110 duration-300">
          <Link
            className="border-b-4 border-transparent hover:border-[#DF500C] "
            to="contact"
            smooth={true}
            duration={500}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* burger */}
      <div onClick={handleClick} className="md:hidden z-10 cursor-pointer">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#5a1c5f] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* social media */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/ivo-ignatov-5188b3133/" target="_blank">
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/ivo959595" target="_blank">
              GitHub <FaGithub size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href={resume} download="Ivo-Ignatov-Resume" 
            >
              Resume <RiDownloadFill className="mr-2"/> <AiOutlineSolution size={30} className="text-[#DF500C]"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
