import React from "react";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import GitHub from "../assets/github.png";
import Tailwind from "../assets/tailwind.png";
import Boostrap from "../assets/bootstrap.png"


import { Link } from "react-scroll";

function Skills() {
  return (
    <div name="skills" className="w-full h-screen bg-[#5a1c5f] text-gray-300 ">
      {/*conattainer*/}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-2xl pt-7 pb-1 font-bold text-[#DF500C]">
            <span className="border-b-4 border-transparent hover:border-[#DF500C] hover:cursor-pointer"> 
            <Link to="skills" smooth={true} duration={500}>
                   Skills
            </Link> 
            </span>
          </p>

          <p className="text-[#adbae0] ">Some of the technologies I have used </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 text-center py-2">

          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer">
            <img className="w-20 mx-auto " src={HTML} alt="HTML icon" />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer">
            <img className="w-20 mx-auto" src={CSS} alt="HTML icon" />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer">
            <img className="w-20 mx-auto" src={JavaScript} alt="HTML icon" />
            <p className="my-4">JavaScript</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer">
            <img className="w-20 mx-auto" src={ReactImg} alt="HTML icon" />
            <p className="my-4">React</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer pt-4">
            <img className="w-20 mx-auto" src={GitHub} alt="HTML icon" />
            <p className="my-4">GitHub</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer pt-4">
            <img className="w-20 mx-auto" src={Tailwind} alt="HTML icon" />
            <p className="my-4">Tailwind</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-90 duration-500 cursor-pointer pt-4">
            <img className="w-20 mx-auto" src={Boostrap} alt="HTML icon" />
            <p className="my-4">Bootstrap</p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Skills;
