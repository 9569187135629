import React, { useState } from "react";
import reactictactoe from "../assets/projects/reactictactoe.PNG";
import CandyMandy from "../assets/projects/candyMandy.PNG";
import wildMemoryMatch from "../assets/projects/wildMemoryMatch.PNG";
import jokeHub from "../assets/projects/jokeHub.PNG"
import cryptivo from '../assets/projects/cryptivo.PNG'

import dailyHoroscope from "../assets/projects/dailyHoroscope.PNG"



import { Link } from "react-scroll";

function Work() {
  const [wildMatchInfoVisibility, setWildMatchInfoVisibility] = useState(false);
  const [candyMandyInfoVisibility, setCandyMandyInfoVisibility] =useState(false);
  const [reactictactoeInfoVisibility, setReactictactoeInfoVisibility] = useState(false);
  const [cryptivoInfoVisibility, setCryptivoInfoVisibility] = useState(false);
  const [jokeHubInfoVisibility, setJokeHubInfoVisibility] = useState(false)
  const [horoscopeInfoVisibility, setHoroscopeInfoVisibility] = useState(false)

  const toggleWildMatchInfo = () => {
    setWildMatchInfoVisibility((prevShowInfo) => !prevShowInfo);
  };

  const toggleCandyMandyInfo = () => {
    setCandyMandyInfoVisibility((prevShowInfo) => !prevShowInfo);
  };

  const toggleReactictactoeInfo = () => {
    setReactictactoeInfoVisibility((prevShowInfo) => !prevShowInfo);
  };

  const toggleCryptivoInfo = () => {
    setCryptivoInfoVisibility((prevShowInfo) => !prevShowInfo);

  };

  const toggleJokeHubInfo = () => {
    setJokeHubInfoVisibility((prevShowInfo) => !prevShowInfo);

  };

  const toggleHoroscopeInfo = () => {
    setHoroscopeInfoVisibility((prevShowInfo) => !prevShowInfo);
  }
  return (
    <div
      name="work"
      className="pt-20 w-full md:h-screen text-gray-300 bg-[#5a1c5f]"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="">
          <p className="pb-1 font-bold text-[#DF500C] text-2xl ">
            <span className="border-b-4 border-transparent hover:border-[#DF500C]  hover:cursor-pointer">
              <Link to="work" smooth={true} duration={500}>
                Work
              </Link>
            </span>
          </p>
          <p className="pb-1 text-[#adbae0] ">
            Here you can check out some of my projects{" "}
          </p>
        </div>

        {/*container*/}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 ">

        {/* {item} */}
        <div
            style={{ backgroundImage: `url(${dailyHoroscope})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
          >
            {/* hover effects */}
            <div
              className="opacity-80 group-hover:opacity-100 /*group-hover:bg-[#0a060a9a]*/ w-full rounded-md
             hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-70 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#DF500C] tracking-wider flex justify-center py-4">
                Daily Horoscope
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://daily-horoscope.netlify.app/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Visit
                    </button>
                  </a>
                  <a href="https://github.com/ivo959595/daily-horoscope"
                   target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>

                  <button
                    onClick={toggleHoroscopeInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>

                {horoscopeInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs z-50" style={{ zIndex: 100 }}>
                     A custom webpage providing dynamic daily horoscopes and other data for a chosen zodiac sign, 
                     all sourced through APIs for accurate and up-to-date information.
                  </div>
                )}
              </div>
            </div>
          </div>


          {/*item*/}
        <div
            style={{ backgroundImage: `url(${jokeHub})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
          >
            {/* hover effects */}
            <div
              className="opacity-80 group-hover:opacity-100 /*group-hover:bg-[#0a060a9a]*/ w-full rounded-md
             hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-70 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#DF500C] tracking-wider flex justify-center py-4">
                JokeHub
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://j0kehub.netlify.app/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Visit
                    </button>
                  </a>
                  <a href="https://github.com/ivo959595/JokeHub"
                   target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>

                  <button
                    onClick={toggleJokeHubInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>

                {jokeHubInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs">
                      A completely custom entertaining app fetching funny jokes through APIs.
                  </div>
                )}
              </div>
            </div>
          </div>

        {/*item*/}
        <div
            style={{ backgroundImage: `url(${cryptivo})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
          >
            {/* hover effects */}
            <div
              className="opacity-80 group-hover:opacity-100 /*group-hover:bg-[#0a060a9a]*/ w-full rounded-md
             hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-70 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#DF500C] tracking-wider flex justify-center py-4">
                Cryptivo
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://cryptivo.netlify.app/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Visit
                    </button>
                  </a>
                  <a href="https://github.com/ivo959595/cryptivo"
                   target="_blank">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>

                  <button
                    onClick={toggleCryptivoInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>

                {cryptivoInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs z-50" style={{ zIndex: 100 }}>                   
                     An app providing comprehensive insights into cryptocurrencies, 
                     including real-time prices, 
                     the latest news updates, and historical data, sourced using APIs.                   
                  </div>
                )}
              </div>
            </div>
          </div>




          {/*item*/}
          <div
            style={{ backgroundImage: `url(${reactictactoe})` }}
            className="bg-black opacity-90 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
            >
            {/* hover effects */}
            <div
              className="opacity-80 group-hover:opacity-100 /*group-hover:bg-[#0a060a9a]*/ w-full rounded-md
             hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-70 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#DF500C] tracking-wider flex justify-center py-4">
                Reactic-Tac-Toe
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://ivo959595.github.io/reactic-tac-toe/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Play
                    </button>
                  </a>
                  <a href="https://github.com/ivo959595/reactic-tac-toe"
                   target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>

                  <button
                    onClick={toggleReactictactoeInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>

                {reactictactoeInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs">
                      A custom Tic-Tac-Toe game. 
                  </div>
                )}
              </div>
            </div>
          </div>


          {/*item*/}
          <div
            style={{ backgroundImage: `url(${CandyMandy})` }}
            className="bg-black shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
          >
            {/* hover effects */}
            <div
              className="opacity-80  group-hover:opacity-100 w-full rounded-md
              hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-80 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#b4b643] tracking-wider flex justify-center py-4">
                Candy Mandy
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://ivo959595.github.io/candy-mandy/"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Play
                    </button>
                  </a>
                  <a
                    href="https://github.com/ivo959595/candy-mandy"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>
                  <button
                    onClick={toggleCandyMandyInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>
                {candyMandyInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs">
                    A custom and simplified version of Candy Crush.
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*item*/}
          <div
            style={{ backgroundImage: `url(${wildMemoryMatch})` }}
            className="bg-black opacity-90 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center mx-auto content-div"
          >
            {/* hover effects */}
            <div
              className="opacity-80  group-hover:opacity-100 w-full rounded-md
              hover:ring-2 hover:ring-[#DF500C] hover:ring-opacity-70 hover:ring-inset hover:ring-shadow-inner"
            >
              <span className="text-2xl font-bold text-[#DF500C] tracking-wider flex justify-center py-4">
                Wild Memory Match
              </span>

              <div className="flex flex-col items-center">
                <div className="flex justify-center">
                  <a
                    href="https://ivo959595.github.io/wild-memory-match/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Play
                    </button>
                  </a>
                  <a
                    href="https://github.com/ivo959595/wild-memory-match"
                    target="_blank"
                  >
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg">
                      Code
                    </button>
                  </a>

                  <button
                    onClick={toggleWildMatchInfo}
                    className="text-center rounded-lg px-4 py-3 m-2 bg-white text-[#DF500C] font-bold text-lg"
                  >
                    Info
                  </button>
                </div>

                {wildMatchInfoVisibility && (
                  <div className="mt-1 p-4 pr-2 bg-gray-200 text-[#DF500C] rounded-lg max-w-xs">
                   A custom memory game with a bit of wildlife.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
