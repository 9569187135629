import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  function handleNameChange(e) {
    setName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    setIsButtonEnabled(
      name.trim() !== "" &&
        email.trim() !== "" &&
        email.includes("@") &&
        message.trim() !== ""
    );
  }, [name, email, message]);

  return (
    <div
      name="contact"
      className="pt-20 px-2 w-full h-screen bg-[#5a1c5f] flex justify-center items-center"
    >
      <form
        method="POST"
        action="https://getform.io/f/lajkdejb"
        className="flex flex-col max-w-[600px] w-full"
      >
        <div className="">
          <p className="pb-1 text-2xl sm:text-2xl font-bold text-[#DF500C] ">
            <span className="border-b-4 border-transparent hover:border-[#DF500C] hover:cursor-pointer">
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </span>
          </p>
          <p className="text-[#adbae0]  pb-1 ">Shoot me a message</p>
        </div>

        <input
          id="nameInput"
          className="
          bg-[#ccd6f6] p-2 rounded-t-lg rounded-b-lg 
          focus:ring-2 focus:ring-[#DF500C] focus:outline-none"
          type="text"
          onChange={handleNameChange}
          placeholder="Name"
          name="name"
        />

        <input
          id="emailInput"
          className="my-4 p-2 bg-[#ccd6f6] rounded-t-lg rounded-b-lg focus:ring-2 focus:ring-[#DF500C] focus:outline-none"
          type="email"
          onChange={handleEmailChange}
          placeholder="Email"
          name="email"
        />
        <textarea
          id="textInput"
          className="bg-[#ccd6f6] p-2 rounded-t-lg rounded-b-lg focus:ring-2 focus:ring-[#DF500C] focus:outline-none"
          name="message"
          rows="10"
          onChange={handleMessageChange}
          placeholder="Message"
        ></textarea>

        <button className={` bg-transparent ${isButtonEnabled 
        ? "hover:bg-[#DF500C] hover:text-white hover:border-transparent"
        : ""}  text-[#DF500C] py-2 px-4 border border-[#c2c2c2] rounded my-8 mx-auto flex items-center

         ${!isButtonEnabled ? "cursor-not-allowed text-red-500" : ""}`}

          title={!isButtonEnabled ? "Please fill the form above correctly" : ""}
          disabled={!isButtonEnabled}>
          Submit Message
        </button> 


      </form>
    </div>
  );
}

export default Contact;
