import React from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link, animateScroll as scroll } from "react-scroll";
import official from "../assets/official.jpg";

import { motion } from "framer-motion";


function Home() {
  return (
    <div name="home" className="w-full h-screen bg-[#5a1c5f] home-div  color-white mb-0">

      {/*container */}
      <div className="max-w-[1000px] mx-auto p-4 py-8 ml-30 flex flex-col sm:flex-row justify-center h-full">
        <div className="flex flex-col sm:flex-row items-center justify-center-10 sm:mt-20 mt-10">

          {/* Text */}
          <div className="mt-2">

            <p className="text-2xl sm:text-4xl text-[#DF500C] delay-15 duration-300">Hi My Name Is
            <h1 className="font-bold text-[#ccd6f6]">
              Ivo Ignatov
            </h1>
            </p>
          
      
            <p className="text-[#adbae0] text-1xl sm:text-2xl  max-w-[700px] delay-15 duration-300">
              <span className="text-1xl sm:text-2xl text-[#DF500C] pr-2 delay-15 duration-300 wobble-span">Welcome</span> to
              my portfolio website! Here, you'll find a showcase of my projects,
              experience, and more. Take a look around to get a glimpse into my
              world of creativity and expertise.
            </p>
          </div>


            {/* Image */}
            <div className="mr-2 sm:mr-4 flex-col sm:order-first mt-2">
            <Link to="home" smooth={true} duration={500}>
              <img
                src={official}
                className="w-60 h-auto sm:w-64 cursor-pointer rounded shadow-xl delay-15 duration-300 "
                alt="Ivo Ignatov"
              ></img>
            </Link>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Home;
