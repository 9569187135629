import React from "react";

import { Link } from "react-scroll";

function About() {
  return (
    <div name="about" className="w-full h-screen bg-[#5a1c5f] text-[#DF500C] about-div mt-0">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right  pl-4">
            <p className="text-2xl  font-bold inline text-center">
              <span className="border-b-4 border-transparent hover:border-[#DF500C] hover:cursor-pointer">

              <Link to="about" smooth={true} duration={500}>
                   About
              </Link>
              </span>
            </p> 


          </div>
        </div>

        <div className="max-w-[1000px] w-full grid 2 gap-8 px-4">
          <div>
            <p className="text-[#adbae0]  text-1xl sm:text-2xl ">
              <span className="text-2xl sm:text-3xl text-[#DF500C] pr-2">
                Hello, I'm Ivo
              </span>
              With a strong background in technical support and customer service, I decided to take my career in a new direction.
              A year and a half ago, I began my first course at SoftUni. 
              Since then, I have completed several more courses and have continuously enhanced my knowledge through independent research. 
              More recently, I started experimenting with building complete projects, including games and websites like the one you are viewing. 
              While my projects may not be perfect or overly complex at this stage, 
              they are crafted with a great deal of interest, passion, and a strong desire to learn.
              Each project represents a significant step forward in my journey of skill development and professional growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
